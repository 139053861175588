input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}

body {
  background-color: #282c34;
}

*::-webkit-scrollbar {
  display: none;
}

.particles-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.app-container {
  font-family: "Roboto Mono";
  height: auto;
  overflow: auto;
  color: white;
}

.main-content {
  text-align: center;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  position: relative;
}

@keyframes fadeInAnimation {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.typewriter h2 {
  overflow: hidden;
  border-right: .15em solid white;
  white-space: nowrap;
  margin: 0 auto;
  padding-top: 10px;
  font-family: courier;
  font-size: 1em;
  animation: typewriter 2s steps(30, end),
             blinkTextCursor 500ms steps(30, end) infinite;
}

@keyframes typewriter {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blinkTextCursor {
  from { border-color: #ed964b; }
  to { border-color: transparent; }
}

.social-icons {
  padding: 10px;
  bottom: 0;
  position: absolute;
}

.social-icons a {
  padding: 0 10px;
}

.icon-link {
  display: inline-block;
  transition: transform 0.3s ease;
}

.social-icon {
  transition: transform 0.3s ease;
}

canvas {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
}

.icon-link:hover .social-icon {
  transform: scale(1.5);
}

.button {
  margin-top: 20px;
  font-family: "Roboto Mono";
  padding: 10px 20px;
  font-size: 1em;
  font-weight: 500;
  width: 150px;
  background-color: #ed964b;
  border: none;
  cursor: pointer;
  border-radius: 35px;
  color: black;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
}

.projects-section {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: white;
}

.stack-section {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stack-skills {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: white;
}

.name {
  font-size: 4em;
  margin-bottom: 0;
  padding: 10px;
  font-family: sans-serif;
}

.skill-item {
  margin: 20px;
  text-align: center;
}

.skill-icon {
  height: 40px;
  filter: invert(1);
}

.skill-label {
  font-family: "Courier", monospace;
  color: white;
}

.media-section {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slide-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slide-content img {
  object-fit: contain;
}